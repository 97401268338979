import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';
import {AuthService} from '../../_services/auth.service';
import {MenuController} from '@ionic/angular';

@Component({
  selector: 'app-sidemenu',
  templateUrl: './sidemenu.component.html',
  styleUrls: ['./sidemenu.component.scss'],
})
export class SidemenuComponent implements OnInit {
  showB2c = false;
  showB2b = false;

  constructor(private readonly router: Router, private readonly authService: AuthService, private readonly menuCtrl: MenuController) { }

  ngOnInit() {}

  toggleSubmenu(menuName) {
    if (menuName === 'b2c') {
      this.showB2c = !this.showB2c;
      this.showB2b = false;
    } else if (menuName === 'b2b') {
      this.showB2b = !this.showB2b;
      this.showB2c = false;
    }
  }

  changeRoute(page) {
      this.router.navigate(['/', page]);
      this.menuCtrl.close();
  }

  logout() {
    this.authService.logout();
  }
}
