import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './_guards/auth.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full',
  },
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then(m => m.LoginPageModule),
  },
  {
    path: 'import-file',
    loadChildren: () => import('./import-file-screen/import-file-screen.module').then(m => m.ImportFileScreenPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'export-file',
    loadChildren: () => import('./export-file-screen/export-file-screen.module').then(m => m.ExportFileScreenPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'delete-user',
    loadChildren: () => import('./delete-user-screen/delete-user-screen.module').then(m => m.DeleteUserScreenPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'import-employees',
    loadChildren: () => import('./import-employees-screen/import-employees-screen.module').then(m => m.ImportEmployeesScreenPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'manage-users',
    loadChildren: () => import('./manage-users/manage-users.module').then( m => m.ManageUsersPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'set-minimum-data-points',
    loadChildren: () => import('./set-minimum-data-points/set-minimum-data-points.module').then( m => m.SetMinimumDataPointsPageModule),
    canActivate: [AuthGuard],
  },

];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
